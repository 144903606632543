import Cookie from 'js-cookie'
import {
    hashFnv32a,
    browser,
    alertDialog,
    close_dialog
} from "./script"

// AJAX for login
function checkLogin() {
    var xhr = new XMLHttpRequest();
    var account = document.getElementById('acc').value;
    var password = document.getElementById('pwd').value;
    var url = location.pathname;

    var cookies = Cookie.get();
    for(var cookie in cookies) {
        Cookie.remove(cookie);
    }
    
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        timeout: 10000,
        data: {
            account: account,
            password: hashFnv32a(password, true)
        },
    })
    .done(function(res) {
        if (res.state == 0) { //&& res.permission > 0
            alertDialog("message.loginsuccess");
            //set cookies
            Cookie.set("account", account);
            Cookie.set("uname", res.name);
            Cookie.set("token", res.token);
            Cookie.set("perm", res.permission);
            setTimeout(function() {
                document.location.pathname = url.replace("login", "");
            }, 300);
        } else {
            alertDialog("message.account_passwd_wrong");
        }
    })
    .fail(function(jqXHR, textStatus) {
        if (textStatus == "timeout") {
            alertDialog("message.timeout");
        }
    })
    .always(function() {
        console.log("complete");
    });
}

/* Run after html loaded complete */
$(function() {
    window.checkLogin = checkLogin;
    $(document).on('keypress', '.login_account_panel input', function(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
            event.preventDefault();
            if ($("#alertDialog").css('display') != "none") {
                close_dialog();
                $("#alertDialog_overlay").unbind("mouseup");
            } else {
                checkLogin();
            }
        }
    });
});