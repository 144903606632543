var osName;
var browser;
// get browser type, set to var browser
function checkBrowser() {
    var nVer = navigator.appVersion;
    var navigatorAgent = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameShift, versionShift, trimSemicolon;

    osName = "unknown";
    if (nVer.indexOf("Win") != -1) {
        osName = "windows";
    } else if (nVer.indexOf("Mac") != -1) {
        osName = "macos";
    } else if (nVer.indexOf("X11") != -1) {
        osName = "unix";
    } else if (nVer.indexOf("Linux") != -1) {
        osName = "linux";
    }

    if ((versionShift = navigatorAgent.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = navigatorAgent.substring(versionShift + 6);
        if ((versionShift = navigatorAgent.indexOf("Version")) != -1)
            fullVersion = navigatorAgent.substring(versionShift + 8);
    } else if ((versionShift = navigatorAgent.indexOf("Edge")) != -1) {
        browserName = "Edge";
        fullVersion = navigatorAgent.substring(versionShift + 5);
    } else if ((versionShift = navigatorAgent.indexOf("MSIE")) != -1) {
        browserName = "IE";
        fullVersion = navigatorAgent.substring(versionShift + 5);
    } else if ((versionShift = navigatorAgent.indexOf("Sleipnir")) != -1) {
        browserName = "Sleipnir";
        fullVersion = navigatorAgent.substring(versionShift + 9);
    } else if ((versionShift = navigatorAgent.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = navigatorAgent.substring(versionShift + 7);
    } else if ((versionShift = navigatorAgent.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = navigatorAgent.substring(versionShift + 7);
        if ((versionShift = navigatorAgent.indexOf("Version")) != -1)
            fullVersion = navigatorAgent.substring(versionShift + 8);
    } else if ((versionShift = navigatorAgent.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = navigatorAgent.substring(versionShift + 8);
    } else if ((nameShift = navigatorAgent.lastIndexOf(" ") + 1) <
        (versionShift = navigatorAgent.lastIndexOf("/"))) {
        browserName = navigatorAgent.substring(nameShift, versionShift);
        fullVersion = navigatorAgent.substring(versionShift + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    if ((trimSemicolon = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, trimSemicolon);
    if ((trimSemicolon = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, trimSemicolon);
    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }
    browser = browserName;
}
/* Check mobile-phone number (Taiwan format)*/
function validPhone(data) {
    var reg =
        /((?=(09))[0-9]{10})$/;
    var status = reg.test(data);
    return status;
}

function validIP(data) {
    var reg =
        /^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])$/;
    var status = reg.test(data);
    return status;
}

/* Check e-mail */
function validMail(value) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(value)
}

function hashFnv32a(str, asString, seed) {
    /*jshint bitwise:false */
    var i, l,
        hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval <<
            24);
    }
    if (asString) {
        // Convert to 8 digit hex string
        return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
}
/* Tab display function*/
function tabDisplay(default_tab = 0) {

    $('ul.tabs li').removeClass('active');

    // 預設顯示第一個 Tab
    var _showTab = default_tab;
    var $defaultLi = $('ul.tabs li').eq(_showTab).addClass('active');
    $($defaultLi.find('a').attr('href')).show();
    $($defaultLi.find('a').attr('href')).siblings().hide();

    // 當 li 頁籤被點擊時...
    // 若要改成滑鼠移到 li 頁籤就切換時, 把 click 改成 mouseover
    $('ul.tabs li').click(function() {
        // 找出 li 中的超連結 href(#id)
        var $this = $(this),
            _clickTab = $this.find('a').attr('href');
        // 把目前點擊到的 li 頁籤加上 .active
        // 並把兄弟元素中有 .active 的都移除 class
        $this.addClass('active').siblings('.active').removeClass('active');
        // 淡入相對應的內容並隱藏兄弟元素
        $(_clickTab).stop(false, true).fadeIn(function() {
            $(this).trigger("fadeInComplete");
        }).siblings().hide();
        return false;
    }).find('a').focus(function() {
        this.blur();
    });

};
/*Unit formator*/
function unitFormator(unit) {
    if (!unit) {
        return ""
    } else if (unit == 'percent') {
        return '%';
    } else if (unit.toUpperCase() == 'C') {
        return '&deg;C';
    } else if (unit.toUpperCase() == 'F') {
        return '&deg;F';
    } else if (unit.toUpperCase() == 'KG/CM2' || unit.toUpperCase() == 'KG / CM^2') {
        return 'Kg/cm<sup>2</sup>';
    }
    return unit;
}

function lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0) / 2;
}

function floatPad(n, width, z) {
    if (n == undefined || n == null || n == "") {
        return ""
    }
    width = width || 3;
    z = z || '0';
    n = n + '';
    if (n.indexOf('.') == -1) {
        n = n + '.';
    }
    return n.length >= (width + n.indexOf('.') + 1) ? n : n + new Array(width + n.indexOf('.') + 1 - n.length + 1).join(z);
}
function indexPad(n, width, z) {
    width = width || 3;
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function generationDatePicker() {
    $(".date-picker-wrapper").remove();
    $('#startDatetime').dateRangePicker({
        startOfWeek: 'monday',
        format: 'YYYY-MM-DD HH:mm',
        autoClose: false,
        singleDate: true,
        language: lang,
        time: {
            enabled: true
        },
        getValue: function() {
            if ($(this).val())
                return $(this).val();
            else
                return '';
        },
        setValue: function(s) {
            $(this).val(s);
        }
    });
    $('#endDatetime').dateRangePicker({
        startOfWeek: 'monday',
        format: 'YYYY-MM-DD HH:mm',
        autoClose: false,
        singleDate: true,
        language: lang,
        time: {
            enabled: true
        },
        getValue: function() {
            if ($(this).val())
                return $(this).val();
            else
                return '';
        },
        setValue: function(s) {
            $(this).val(s);
        }
    });
}
/*Generate alart dialog*/
function alertDialog(content, parm) {
    var _window_width = window.innerWidth;
    var _scroll_top = $(window).scrollTop();
    var _window_height = $('#alertDialog_overlay').height();
    var _top = 153; //(_window_height-485) / 2;
    // var _top = _scroll_top + (_window_height-485) / 2;
    // _top = _top < (_scroll_top+19) ? (_scroll_top+19) : _top;
    $('#alertDialog div').hide();
    $('#alertDialog').css('top', _top);
    $('#alertDialog p').remove();

    var contentHtml = "<p data-localize=" + content + ">" + I18n.t(content) + "</p>";
    $('#alertDialog div').before(contentHtml);
    // $("[data-localize]").localize("../lang/locale", {language: lang});
    if (parm != undefined) {
        var localizeText = $('#alertDialog p').html();
        for (var i = 0; i < parm.length; ++i) {
            localizeText = localizeText.replace('%' + (i + 1), parm[i]);
        }
        $('#alertDialog p').html(localizeText);
    }

    $('#alertDialog_overlay, #alertDialog').fadeIn(300);

    $('.close_pop').click(function(e) {
        e.preventDefault();
        close_dialog();
    });
    $('#alertDialog_overlay').mouseup(function(e) {
        var container = $("#alertDialog");
        if (!container.is(e.target) // if the target of the click isn't the container...
            &&
            container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            close_dialog();
            $("#alertDialog_overlay").unbind("mouseup");
        }
    });
}
/* Generate confirm dialog */
function confirmDialog(content, parm, inputType) {
    var _window_width = window.innerWidth;
    var _scroll_top = $(window).scrollTop();
    var _window_height = $('#alertDialog_overlay').height();
    var _top = 153; //(_window_height-485) / 2;
    // var _top = _scroll_top + (_window_height-485) / 2;
    // _top = _top < (_scroll_top+19) ? (_scroll_top+19) : _top;

    $('#alertDialog div').show();
    $('#alertDialog').css('top', _top);
    $('#alertDialog p').remove();
    if (inputType == undefined) {
        $("#returnValue").hide();
    } else {
        $("#returnValue").show();
        $("#returnValue").attr("type", inputType);
    }
    var contentHtml = "<p data-localize=" + content + ">" + I18n.t(content) + "</p>";
    $('#alertDialog div').before(contentHtml);
    // $("[data-localize]").localize("../lang/locale", {language: lang});
    if (parm != undefined) {
        var localizeText = $('#alertDialog p').html();
        for (var i = 0; i < parm.length; ++i) {
            localizeText = localizeText.replace('%' + (i + 1), parm[i]);
        }
        $('#alertDialog p').html(localizeText);
    }
    $('#alertDialog_overlay, #alertDialog').fadeIn(300);

    var def = $.Deferred();
    $('#okbtn').bind("click", function(e) {
        e.preventDefault();
        if (inputType != undefined) {
            var returnValue = $("#returnValue").val();
            if (returnValue == "") {
                $("#returnValue").focus();
                // close_dialog();
                // def.resolve(returnValue);
            } else {
                $("#okbtn").unbind("click");
                $("#cancelbtn").unbind("click");
                $(".close_pop").unbind("click");
                close_dialog();
                $("#returnValue").val("");
                def.resolve(returnValue);
            }
        } else {
            $("#okbtn").unbind("click");
            $("#cancelbtn").unbind("click");
            $(".close_pop").unbind("click");
            close_dialog();
            def.resolve(true);
        }
    });
    $('#cancelbtn').click(function(e) {
        $("#okbtn").unbind("click");
        $("#cancelbtn").unbind("click");
        $(".close_pop").unbind("click");
        e.preventDefault();
        close_dialog();
        def.resolve(false);
    });
    $('.close_pop').click(function(e) {
        $("#okbtn").unbind("click");
        $("#cancelbtn").unbind("click");
        $(".close_pop").unbind("click");
        e.preventDefault();
        close_dialog();
        def.resolve(false);
    });
    return def.promise();
}
function submit_Logout() {
    confirmDialog("message.confirmlogout").done(function(result) {
        if (result == false) return;
        forwardLogin();
    });
}
function forwardLogin() {
    if (document.location.pathname.indexOf("/edit") == 0) {
        document.location.pathname = "/edit/logout";
    } else {
        document.location.pathname = "/logout";
    }
}
function checkToken(res) {
    if (res.message == "ILLEGAL_TOKEN") {
        forwardLogin();
    } 
}
/* Close dialog */
function close_dialog() {
    $('#alertDialog_overlay, #alertDialog').fadeOut(300);
}
checkBrowser()
export {
    osName,
    browser,
    hashFnv32a,
    validPhone,
    validIP,
    validMail,
    tabDisplay,
    unitFormator,
    lengthInUtf8Bytes,
    floatPad,
    indexPad,
    generationDatePicker,
    alertDialog,
    confirmDialog,
    close_dialog,
    checkToken,
    forwardLogin,
    submit_Logout
}